.container{
    margin-top: 25px;
}

.signform{
    border: 2px solid black;
}

.fields input{
    width: 200px;
}

h3{
    font-size: 30px;
}

.vertical-border{
    width:5px;
    height:200px;
    border-left: 5px solid #033859;
    padding-right: 30px;
}

table, tr, td, a{

    font-family: Montserrat, sans-serif;

}

span img{
    margin-bottom: 1px;
}

.button-clipboard{
    -webkit-user-select: none;
}

.copy{
    color:#033859;
    border-color: #033859;
}

.copy:hover{
    background-color:#033859;
    color:white;
    border-color: #033859;
}